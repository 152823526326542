import React, { useContext, useEffect, useState } from "react";
import {
  Classes,
  Quaters,
  years,
} from "../../../data/modules/equityModelScenarioX";
import {
  CurrencyFormatter,
  NumberFormatter,
  PercentFormatter,
  PercentFormatter1,
} from "../../utils/Utils";
import { EquityModelScenarioXContext } from "../../../context/equityModelScenarioXContext";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

const ScenarioX = () => {
  const {
    allOutputScenario,
    originalAllScenarioOutput,
    currYear,
    outputTypeToggle,
  } = useContext(EquityModelScenarioXContext);

  const [currentScenarioData, setCurrentScenarioData] = useState(
    allOutputScenario.filter((scenario) => scenario.name === "Scenario C")[0]
  );

  const [originalCurrentScenarioData, setOriginalCurrentScenarioData] =
    useState(
      originalAllScenarioOutput.filter(
        (scenario) => scenario.name === "Scenario C"
      )[0]
    );

  const tableDataInfo = [
    {
      modelType: currentScenarioData,
      year: 2023,
    },
    {
      modelType: originalCurrentScenarioData,
      year: 2024,
    },
    {
      modelType: currentScenarioData,
      year: 2024,
    },
  ];

  return (
    <>
      <div className="mt-8 w-full">
        <div className="border shadow rounded-lg overflow-auto mb-5 w-fit max-h-[570px]">
          <table className={`w-fit text-xs`}>
            <thead className="bg-gray-400 sticky top-0">
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>

                <th className="p-1 pt-2" colSpan={2}>
                  YE 2024 Pool Value for New Class C
                </th>
                <th className="p-1 pt-2" colSpan={2}>
                  Delta (Scenario X - as-is){" "}
                </th>
              </tr>
              <tr>
                <th className="w-28 pb-2">Class</th>
                <th className="w-28">Quater</th>
                <th className="">Information Type</th>
                <th className="px-6">YE 2023</th>

                <th className="w-28">
                  <div className="flex gap-x-1 justify-center items-center">
                    <p>2024 as-is</p>
                    <span data-tooltip-id={"2024 as-is"}>
                      <AiOutlineInfoCircle className="cursor-pointer" />
                    </span>
                    <Tooltip
                      id={"2024 as-is"}
                      place="bottom"
                      content={"D+ C17,18, 20,24,28, BB"}
                      variant="dark"
                      style={{
                        width: "fit-content",
                        fontSize: 10,
                        textAlign: "left",
                      }}
                    />
                  </div>
                </th>
                <th className="w-30">
                  <div className="flex gap-x-1 justify-center items-center">
                    <p>2024 Scenario X</p>
                    <span data-tooltip-id={"2024 scenario x"}>
                      <AiOutlineInfoCircle className="cursor-pointer" />
                    </span>
                    <Tooltip
                      id={"2024 scenario x"}
                      place="bottom"
                      content={"D+ C17,18, 20,24,28, BB"}
                      variant="dark"
                      style={{
                        width: "fit-content",
                        fontSize: 10,
                        textAlign: "left",
                      }}
                    />
                  </div>
                </th>

                <th className="w-28">$</th>
                <th className="w-28">%</th>
              </tr>
            </thead>
            <tbody>
              {/* Total Class Row */}
              <tr className="font-bold border">
                <td className="py-4 text-center">Total Class</td>
                <td className="py-4 text-center"></td>
                <td className="py-4 text-center">
                  <div className="flex flex-col items-center justify-center gap-y-1">
                    {outputTypeToggle === "poolValue" && (
                      <>
                        <div className="whitespace-nowrap">
                          Available Amount
                        </div>
                        <div className="whitespace-nowrap">
                          Outstanding Shares
                        </div>
                      </>
                    )}
                    <div
                      className={`${
                        outputTypeToggle === "poolValue" && "border-t-2"
                      } w-fit whitespace-nowrap`}
                    >
                      Per Unit Liquidation Value
                    </div>
                    <div className="whitespace-nowrap">
                      Available amount/Outstanding Shares
                    </div>
                  </div>
                </td>

                {tableDataInfo.map((column, index) => (
                  <td className="py-4 text-center" key={index}>
                    <div className="flex flex-col items-center justify-center gap-y-1">
                      <div className="flex flex-col items-end">
                        <div>
                          {CurrencyFormatter(
                            column.modelType["available_c_pool"][
                              years.indexOf(column.year)
                            ],
                            2
                          )}
                        </div>
                        <div>
                          {NumberFormatter(
                            column.modelType[
                              "sub_total_all_c_vested_cummulative"
                            ][years.indexOf(column.year)],
                            2
                          )}
                        </div>
                        <div>
                          {CurrencyFormatter(
                            column.modelType["distribution_per_unit_c"][
                              years.indexOf(column.year)
                            ],
                            2
                          )}
                        </div>

                        <div>
                          {CurrencyFormatter(
                            column.modelType["available_c_pool"][
                              years.indexOf(column.year)
                            ] /
                              column.modelType[
                                "sub_total_all_c_vested_cummulative"
                              ][years.indexOf(column.year)],
                            2
                          )}
                        </div>
                      </div>
                    </div>
                  </td>
                ))}

                {/* Dollar Value */}
                <td className="py-4 text-center">
                  <div className="flex flex-col items-center justify-center gap-y-1">
                    <div className="flex flex-col items-end">
                      <div className="text-red-500">
                        (
                        {CurrencyFormatter(
                          currentScenarioData["available_c_pool"][
                            years.indexOf(2024)
                          ] -
                            originalCurrentScenarioData["available_c_pool"][
                              years.indexOf(2024)
                            ],
                          1,
                          "accounting"
                        )}
                        )
                      </div>
                      <div className="text-red-500">
                        (
                        {NumberFormatter(
                          currentScenarioData[
                            "sub_total_all_c_vested_cummulative"
                          ][years.indexOf(2024)] -
                            originalCurrentScenarioData[
                              "sub_total_all_c_vested_cummulative"
                            ][years.indexOf(2024)],
                          1,
                          "accounting"
                        )}
                        )
                      </div>
                      <div className="text-red-500">
                        (
                        {CurrencyFormatter(
                          currentScenarioData["distribution_per_unit_c"][
                            years.indexOf(2024)
                          ] -
                            originalCurrentScenarioData[
                              "distribution_per_unit_c"
                            ][years.indexOf(2024)],
                          1,
                          "accounting"
                        )}
                        )
                      </div>

                      <div className="text-red-500">
                        (
                        {CurrencyFormatter(
                          currentScenarioData["available_c_pool"][
                            years.indexOf(2024)
                          ] /
                            currentScenarioData[
                              "sub_total_all_c_vested_cummulative"
                            ][years.indexOf(2024)] -
                            originalCurrentScenarioData["available_c_pool"][
                              years.indexOf(2024)
                            ] /
                              originalCurrentScenarioData[
                                "sub_total_all_c_vested_cummulative"
                              ][years.indexOf(2024)],
                          1,
                          "accounting"
                        )}
                        )
                      </div>
                    </div>
                  </div>
                </td>

                {/* Percentage Value */}
                <td className="py-4 text-center italic">
                  <div className="flex flex-col items-center justify-center gap-y-1">
                    <div className="flex flex-col items-end">
                      <div className="text-red-500">
                        (
                        {PercentFormatter(
                          (currentScenarioData["available_c_pool"][
                            years.indexOf(2024)
                          ] -
                            originalCurrentScenarioData["available_c_pool"][
                              years.indexOf(2024)
                            ]) /
                            originalCurrentScenarioData["available_c_pool"][
                              years.indexOf(2024)
                            ],
                          0,
                          "accounting"
                        )}
                        )
                      </div>
                      <div className="text-red-500">
                        (
                        {PercentFormatter(
                          (currentScenarioData[
                            "sub_total_all_c_vested_cummulative"
                          ][years.indexOf(2024)] -
                            originalCurrentScenarioData[
                              "sub_total_all_c_vested_cummulative"
                            ][years.indexOf(2024)]) /
                            originalCurrentScenarioData[
                              "sub_total_all_c_vested_cummulative"
                            ][years.indexOf(2024)],
                          0,
                          "accounting"
                        )}
                        )
                      </div>
                      <div className="text-red-500">
                        (
                        {PercentFormatter(
                          (currentScenarioData["distribution_per_unit_c"][
                            years.indexOf(2024)
                          ] -
                            originalCurrentScenarioData[
                              "distribution_per_unit_c"
                            ][years.indexOf(2024)]) /
                            originalCurrentScenarioData[
                              "distribution_per_unit_c"
                            ][years.indexOf(2024)],
                          0,
                          "accounting"
                        )}
                        )
                      </div>

                      <div className="text-red-500">
                        (
                        {PercentFormatter(
                          (currentScenarioData["available_c_pool"][
                            years.indexOf(2024)
                          ] /
                            currentScenarioData[
                              "sub_total_all_c_vested_cummulative"
                            ][years.indexOf(2024)] -
                            originalCurrentScenarioData["available_c_pool"][
                              years.indexOf(2024)
                            ] /
                              originalCurrentScenarioData[
                                "sub_total_all_c_vested_cummulative"
                              ][years.indexOf(2024)]) /
                            (originalCurrentScenarioData["available_c_pool"][
                              years.indexOf(2024)
                            ] /
                              originalCurrentScenarioData[
                                "sub_total_all_c_vested_cummulative"
                              ][years.indexOf(2024)]),
                          0,
                          "accounting"
                        )}
                        )
                      </div>
                    </div>
                  </div>
                </td>
              </tr>

              {/* Individual Class */}
              {Classes.map((currClass, index) => (
                <tr key={index} className="border">
                  <td className="py-4 text-center">{currClass}</td>
                  <td className="py-4 text-center">
                    {Quaters[index] === "Q220"
                      ? "Q220 and earlier"
                      : Quaters[index]}
                  </td>
                  <td className="text-center py-3">
                    <div className="flex flex-col items-center justify-center">
                      <div className="">Available Amount</div>
                      {/* <div>Outstanding Shares</div> */}
                      <div className=" border-t-2 w-fit">
                        Per Unit Liquidation Value
                      </div>
                    </div>
                  </td>

                  {tableDataInfo.map((column, idx) => (
                    <td className="py-4 text-center" key={idx}>
                      <div className="flex flex-col items-center justify-center gap-y-1">
                        <div className="flex flex-col items-end">
                          <div>
                            {CurrencyFormatter(
                              column.modelType[
                                "available_amt_per_unit_class_c"
                              ][`${currClass}${Quaters[index]}`][
                                years.indexOf(column.year)
                              ],
                              1
                            )}
                          </div>

                          <div>
                            {CurrencyFormatter(
                              column.modelType[
                                "liquidation_value_per_unit_class"
                              ][`${currClass}${Quaters[index]}`][
                                years.indexOf(column.year)
                              ],
                              4
                            )}
                          </div>
                        </div>
                      </div>
                    </td>
                  ))}

                  {/* Delta Dollar Value */}
                  <td className="py-4 text-center">
                    <div className="flex flex-col items-center justify-center gap-y-1">
                      <div className="flex flex-col items-end">
                        <div className="text-red-500">
                          (
                          {CurrencyFormatter(
                            currentScenarioData[
                              "available_amt_per_unit_class_c"
                            ][`${currClass}${Quaters[index]}`][
                              years.indexOf(2024)
                            ] -
                              originalCurrentScenarioData[
                                "available_amt_per_unit_class_c"
                              ][`${currClass}${Quaters[index]}`][
                                years.indexOf(2024)
                              ],
                            1,
                            "accounting"
                          )}
                          )
                        </div>

                        <div className="text-red-500">
                          (
                          {CurrencyFormatter(
                            currentScenarioData[
                              "liquidation_value_per_unit_class"
                            ][`${currClass}${Quaters[index]}`][
                              years.indexOf(2024)
                            ] -
                              originalCurrentScenarioData[
                                "liquidation_value_per_unit_class"
                              ][`${currClass}${Quaters[index]}`][
                                years.indexOf(2024)
                              ],
                            1,
                            "accounting"
                          )}
                          )
                        </div>
                      </div>
                    </div>
                  </td>

                  {/* Delta Percentage Value */}
                  <td className="py-4 text-center">
                    <div className="flex flex-col items-center justify-center gap-y-1">
                      <div className="flex flex-col items-end">
                        <div className="text-red-500 italic">
                          (
                          {PercentFormatter(
                            (currentScenarioData[
                              "available_amt_per_unit_class_c"
                            ][`${currClass}${Quaters[index]}`][
                              years.indexOf(2024)
                            ] -
                              originalCurrentScenarioData[
                                "available_amt_per_unit_class_c"
                              ][`${currClass}${Quaters[index]}`][
                                years.indexOf(2024)
                              ]) /
                              originalCurrentScenarioData[
                                "available_amt_per_unit_class_c"
                              ][`${currClass}${Quaters[index]}`][
                                years.indexOf(2024)
                              ],
                            0,
                            "accounting"
                          )}
                          )
                        </div>

                        <div className="text-red-500 italic">
                          (
                          {PercentFormatter(
                            (currentScenarioData[
                              "liquidation_value_per_unit_class"
                            ][`${currClass}${Quaters[index]}`][
                              years.indexOf(2024)
                            ] -
                              originalCurrentScenarioData[
                                "liquidation_value_per_unit_class"
                              ][`${currClass}${Quaters[index]}`][
                                years.indexOf(2024)
                              ]) /
                              originalCurrentScenarioData[
                                "liquidation_value_per_unit_class"
                              ][`${currClass}${Quaters[index]}`][
                                years.indexOf(2024)
                              ],
                            0,
                            "accounting"
                          )}
                          )
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ScenarioX;
