import { createContext, useEffect, useState } from "react";
import { Classes, initial_model_input } from "../data/modules/equityModelScenarioX";

export const EquityModelScenarioXContext = createContext();

export const EquityModelScenarioXContextProvider = (props) => {
  const [sidebar, setSidebar] = useState("input");
  // const [currentInputTab, setCurrentInputTab] = useState(AllInputTabs[0]);
  const [currentOutputTab, setCurrentOutputTab] = useState("Scenario");
  const [modelInputs, setModelInputs] = useState(initial_model_input);
  const [currOutputData, setCurrOutputData] = useState(null);
  const [allOutputScenario, setAllOutputScenario] = useState([]);
  const [scenarioComparisonOutputs, setScenarioComparisonOutputs] = useState();
  const [currentScenarioIndex, setCurrentScenarioIndex] = useState(1);
  const [showOutput, setShowOutput] = useState(false);
  const [load, setLoad] = useState(false);
  const [currYear, setCurrYear] = useState(2024);
  const [outputTypeToggle, setOutputTypeToggle] = useState("poolValue");
  const [showSaveScenario, setShowSaveScenario] = useState(false);
  const [showError, setShowError] = useState("");


  // Original Equity Model Data
  const [originalAllScenarioOutput, setOriginalAllScenarioOutput] = useState([])
  

  return (
    <EquityModelScenarioXContext.Provider
      value={{
        sidebar,
        setSidebar,
        currentOutputTab,
        setCurrentOutputTab,
        modelInputs,
        setModelInputs,
        currOutputData,
        setCurrOutputData,
        allOutputScenario,
        setAllOutputScenario,
        scenarioComparisonOutputs,
        setScenarioComparisonOutputs,
        currentScenarioIndex,
        setCurrentScenarioIndex,
        showOutput,
        setShowOutput,
        load,
        setLoad,
        currYear,
        setCurrYear,
        outputTypeToggle,
        setOutputTypeToggle,
        showSaveScenario,
        setShowSaveScenario,
        showError,
        setShowError,
        originalAllScenarioOutput,
        setOriginalAllScenarioOutput
      }}
    >
      {props.children}
    </EquityModelScenarioXContext.Provider>
  );
};
