import React, { useContext, useEffect, useState } from "react";
import { IoIosAdd } from "react-icons/io";
import { CiSquareMinus, CiSquarePlus } from "react-icons/ci";
import {
  Classes,
  engineRows,
  expandableRows,
  Quaters,
  years,
} from "../../../data/modules/equityModel";
import {
  CurrencyFormatter,
  NumberFormatter,
  PercentFormatter1,
} from "../../utils/Utils";
import { EquityModelContext } from "../../../context/equityModelContext";

const ScenarioOutput = ({ outputData }) => {
  const { outputTypeToggle } = useContext(EquityModelContext);
  const [expandedRows, setExpandedRows] = useState({});

  const handleRowClick = (value) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [value]: !prevState[value],
    }));
  };

  // Check if parent row is set to true to show the child row
  // The '!!' double exclamation means to convert the value into boolean.
  const isExpanded = (parentRow) => !!expandedRows[parentRow];

  const renderSubRows = (parentRow) => {
    const subRows = engineRows.filter((row) => {
      return expandableRows[parentRow].includes(row.value);
    });

    return subRows.map((subRow, index) => (
      <tr key={index} className="border even:bg-gray-100 odd:bg-white">
        <td></td>
        <td className="py-2 text-left pl-2 w-fit">{subRow.value}</td>
        {years.map((year, idx) => (
          <td className="py-2 text-center" key={idx}>
            {CurrencyFormatter(
              outputData["engine_output"][subRow.value][idx],
              1
            )}
          </td>
        ))}
      </tr>
    ));
  };

  return (
    <>
      {outputData && (
        <div className="my-4">
          {/* Pool Value */}
          {outputTypeToggle === "poolValue" && (
            <div
              className={`border shadow rounded-lg overflow-x-auto mb-5 max-h-[570px]`}
            >
              <table className="min-w-full text-xs">
                <thead className="bg-gray-400 sticky top-0 text-gray-700">
                  <th className="p-4">Class</th>
                  <th className="px-4">Quater</th>
                  <th className="px-4">Information Type</th>
                  {years.map((year, index) => (
                    <th className="" key={index}>
                      YE {year}
                    </th>
                  ))}
                </thead>
                <tbody className="border">
                  {/* Total Class for Pool Value*/}
                  <tr className="font-bold border">
                    <td className="py-4 text-center px-4">Total Class</td>
                    <td className="py-4 text-center px-4"></td>
                    <td className="py-4 text-center px-4">
                      <div className="flex flex-col items-center justify-center gap-y-1 whitespace-nowrap">
                        <div className="">Available Amount</div>
                        <div className="">Outstanding Shares</div>
                        <div className=" border-t-2">
                          Per Unit Liquidation Value
                        </div>
                        <div>Available amount/Outstanding Shares</div>
                      </div>
                    </td>
                    {years.map((year, idx) => (
                      <td className="text-center" key={idx}>
                        <div className="flex flex-col gap-y-1 gap-x-4 px-4">
                          <div>
                            {CurrencyFormatter(
                              outputData["available_c_pool"][idx],
                              1
                            )}
                          </div>
                          <div>
                            {NumberFormatter(
                              outputData["sub_total_all_c_vested_cummulative"][
                                idx
                              ],
                              1
                            )}
                          </div>
                          <div className="border-t-2">
                            {CurrencyFormatter(
                              outputData["distribution_per_unit_c"][idx],
                              2
                            )}
                          </div>
                          <div className="">
                            {CurrencyFormatter(
                              outputData["available_c_pool"][idx] /
                                outputData[
                                  "sub_total_all_c_vested_cummulative"
                                ][idx],
                              2
                            )}
                          </div>
                        </div>
                      </td>
                    ))}
                  </tr>
                  {/* Individual Class for Pool Value */}
                  {Classes.map((item, index) => (
                    <tr key={index} className="border">
                      <td className="text-center ">{item}</td>
                      <td className="text-center">
                        {Quaters[index] === "Q220"
                          ? "Q220 and earlier"
                          : Quaters[index]}
                      </td>
                      <td className="text-center py-3">
                        <div className="flex flex-col items-center justify-center gap-y-1">
                          <div className="">Available Amount</div>
                          <div className="">Outstanding Shares</div>
                          <div className=" border-t-2 ">
                            Per Unit Liquidation Value
                          </div>
                        </div>
                      </td>
                      {years.map((year, idx) => (
                        <td className="text-center" key={idx}>
                          <div className="flex flex-col gap-y-1">
                            <div>
                              {CurrencyFormatter(
                                outputData["available_amt_per_unit_class_c"][
                                  `${item}${Quaters[index]}`
                                ][idx],
                                1
                              )}
                            </div>
                            <div>
                              {NumberFormatter(
                                outputData["detailed_class_c_unit"][
                                  `${item}${Quaters[index]}`
                                ][idx],
                                1
                              )}
                            </div>
                            <div className="border-t-2">
                              {CurrencyFormatter(
                                outputData["liquidation_value_per_unit_class"][
                                  `${item}${Quaters[index]}`
                                ][idx],
                                3
                              )}
                            </div>
                          </div>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/* CAGR */}
          {outputTypeToggle === "cagr" && (
            <div
              className={`border shadow rounded-lg overflow-auto mb-5 w-full max-h-[570px]`}
            >
              <table className="w-full text-xs">
                <thead className="bg-gray-400 sticky top-0">
                  <th className="py-4 w-32">Class</th>
                  <th className="py-4 w-24">Quater</th>
                  <th className="py-4 w-64">Information Type</th>
                  {years.slice(1, years.length).map((year, index) => (
                    <th className="py-4" key={index}>
                      YE {year}
                    </th>
                  ))}
                </thead>
                <tbody>
                  {/* Total Class for CAGR */}
                  <tr className="font-bold border">
                    <td className="py-4 text-center">Total Class</td>
                    <td className="py-4 text-center"></td>
                    <td className="py-4 ">
                      <div className="flex flex-col items-center justify-center gap-y-2">
                        <div>Per Unit Liquidation Value</div>
                        <div>Available amount/Outstanding Shares</div>
                      </div>
                    </td>
                    {years.slice(1, years.length).map((year, idx) => (
                      <td className="py-4 text-center" key={idx}>
                        <div className="flex flex-col gap-y-2">
                          <div>
                            {PercentFormatter1(
                              (outputData["distribution_per_unit_c"][idx + 1] /
                                outputData["distribution_per_unit_c"][0]) **
                                (1 / (idx + 1)) -
                                1
                            )}
                          </div>
                          <div>
                            {PercentFormatter1(
                              (outputData["available_c_pool"][idx + 1] /
                                outputData[
                                  "sub_total_all_c_vested_cummulative"
                                ][idx + 1] /
                                (outputData["available_c_pool"][0] /
                                  outputData[
                                    "sub_total_all_c_vested_cummulative"
                                  ][0])) **
                                (1 / (idx + 1)) -
                                1
                            )}
                          </div>
                        </div>
                      </td>
                    ))}
                  </tr>

                  {/* Individual Class for CAGR */}
                  {Classes.map((item, index) => (
                    <tr className="border">
                      <td className="py-4 text-center">{item}</td>
                      <td className="py-4 text-center">
                        {Quaters[index] === "Q220"
                          ? "Q220 and earlier"
                          : Quaters[index]}
                      </td>
                      <td className="py-4 text-center">
                        Per Unit Liquidation Value
                      </td>
                      {years.slice(1, years.length).map((year, idx) => (
                        <td className="py-4 text-center">
                          {PercentFormatter1(
                            outputData["liquidation_cagr"][
                              `${item}${Quaters[index]}`
                            ][idx]
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/* Engine Output */}
          {outputTypeToggle === "engine" && (
            <div
              className={`border shadow rounded-lg overflow-auto mb-5 w-full max-h-[570px]`}
            >
              <table className="w-full text-xs">
                <thead className="bg-gray-400 sticky top-0 z-[1]">
                  <tr>
                    <th></th>
                    <th className="p-4 text-left whitespace-nowrap">
                      Financials
                    </th>
                    {years.map((year, index) => (
                      <th className="" key={index}>
                        YE {year}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {engineRows.map((row, index) => (
                    <React.Fragment key={index}>
                      {!Object.values(expandableRows)
                        .flat()
                        .includes(row.value) && (
                        <tr
                          key={index}
                          className="border even:bg-gray-100 odd:bg-white"
                        >
                          <td className="pl-[7px]">
                            {expandableRows[row.value] &&
                              expandableRows[row.value].length > 0 && (
                                <span
                                  className="text-lg font-bold w-5 cursor-pointer text-center"
                                  onClick={() => handleRowClick(row.value)}
                                >
                                  {isExpanded(row.value) ? (
                                    <CiSquareMinus />
                                  ) : (
                                    <CiSquarePlus />
                                  )}
                                </span>
                              )}
                          </td>

                          <td className="py-2 text-left whitespace-nowrap px-2">
                            {row.label}
                          </td>
                          {years.map((year, idx) => (
                            <td className="py-2 text-center px-4" key={idx}>
                              {row.label ===
                              "C Units Issued & Vested (in million units) (B)"
                                ? NumberFormatter(
                                    outputData["engine_output"][row.value][idx],
                                    1
                                  )
                                : CurrencyFormatter(
                                    outputData["engine_output"][row.value][idx],
                                    1
                                  )}
                            </td>
                          ))}
                        </tr>
                      )}
                      {isExpanded(row.value) && renderSubRows(row.value)}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ScenarioOutput;
