export const initial_model_input = [
  // Baseline
  {
    description: "Baseline",
    annual_revenue_growth: 6, // percentage
    acquisitions: 50,
    ebitda_multiple_EV: 14,
    YE_cash: 250,
    pipeline_value: 50,
    debt: 6,
    class_A: 6.14, // percentage
    class_B: 89.371, // percentage
    new_class_C_2024: 0, // C17
    // new_class_C_2nd_2024: 0, // C18
    // new_class_C_2025: 0, //C20
    // new_class_C_2026: 0, // C24
    // new_class_C_2027: 0, // C28
    // implied_new_class_C17_Q224: 0,
    // implied_new_class_C18_Q324: 0,
    // implied_new_class_C20_2024_2025: 0,
    // implied_new_class_C24_2025_2026: 0,
    // implied_new_class_C28_2026_2027: 0,
    class_C_vesting_period: 5,
    share_buyback_2024: 0,
    share_buyback_rate_2024: 0,
    class_D_vesting_period: 4,
    class_D_target_value: 0, // percentage
  },
  // Scenario A
  {
    description: "C17 only",
    annual_revenue_growth: 6, // percentage
    acquisitions: 50,
    ebitda_multiple_EV: 14,
    YE_cash: 250,
    pipeline_value: 50,
    debt: 6,
    class_A: 6.14, // percentage
    class_B: 89.371, // percentage
    new_class_C_2024: 67.23, // C17
    // new_class_C_2nd_2024: 0, // C18
    // new_class_C_2025: 0, // C20
    // new_class_C_2026: 0, // C24
    // new_class_C_2027: 0, // C28
    // implied_new_class_C17_Q224: 17.963,
    // implied_new_class_C18_Q324: 0,
    // implied_new_class_C20_2024_2025: 0,
    // implied_new_class_C24_2025_2026: 0,
    // implied_new_class_C28_2026_2027: 0,
    class_C_vesting_period: 5,
    share_buyback_2024: 0,
    share_buyback_rate_2024: 0,
    class_D_vesting_period: 4,
    class_D_target_value: 0, // percentage
  },
  // Scenario B
  {
    description: "D+ C17",
    annual_revenue_growth: 6, // percentage
    acquisitions: 50,
    ebitda_multiple_EV: 14,
    YE_cash: 250,
    pipeline_value: 50,
    debt: 6,
    class_A: 6.14, // percentage
    class_B: 89.371, // percentage
    new_class_C_2024: 64.52, // C17
    // new_class_C_2nd_2024: 0, // C18
    // new_class_C_2025: 0, // C20
    // new_class_C_2026: 0, // C24
    // new_class_C_2027: 0, // C28
    // implied_new_class_C17_Q224: 17.963,
    // implied_new_class_C18_Q324: 0,
    // implied_new_class_C20_2024_2025: 0,
    // implied_new_class_C24_2025_2026: 0,
    // implied_new_class_C28_2026_2027: 0,
    class_C_vesting_period: 5,
    share_buyback_2024: 0,
    share_buyback_rate_2024: 0,
    class_D_vesting_period: 4,
    class_D_target_value: 1.35, // percentage
  },
  // Scenario C
  {
    description: "D+ C17, 18, 20, 24, 28, BB",
    annual_revenue_growth: 6, // percentage
    acquisitions: 50,
    ebitda_multiple_EV: 14,
    YE_cash: 250,
    pipeline_value: 50,
    debt: 6,
    class_A: 6.14, // percentage
    class_B: 89.371, // percentage
    new_class_C_2024: 62.55, // C17
    // new_class_C_2nd_2024: 85.2125, // C18
    // new_class_C_2025: 50, // C20
    // new_class_C_2026: 50, // C24
    // new_class_C_2027: 50, // C28
    // implied_new_class_C17_Q224: 17.963,
    // implied_new_class_C18_Q324: 18.53,
    // implied_new_class_C20_2024_2025: 11,
    // implied_new_class_C24_2025_2026: 10,
    // implied_new_class_C28_2026_2027: 11,
    class_C_vesting_period: 5,
    share_buyback_2024: 50,
    share_buyback_rate_2024: 2.7591,
    class_D_vesting_period: 4,
    class_D_target_value: 1.5, // percentage
  },
];

export const inputScenarios = [
  "Baseline",
  "Scenario A",
  "Scenario B",
  "Scenario C",
];

export const modelInputRows = [
  {
    label: "Scenario Description",
    value: "description",
    inputType: "text",
  },
  {
    label: "Annual Revenue Growth",
    value: "annual_revenue_growth",
    inputType: "percentage",
  },
  { label: "Acquisitions", value: "acquisitions", inputType: "currency" },
  {
    label: "EBITDA multiple for Enterprise Value",
    value: "ebitda_multiple_EV",
    inputType: "number",
  },
  {
    label: "Forward-looking YE cash ($M)",
    value: "YE_cash",
    inputType: "currency",
  },
  {
    label: "Forward-looking pipeline value ($M)",
    value: "pipeline_value",
    inputType: "currency",
  },
  { label: "Forward-looking debt ($M)", value: "debt", inputType: "currency" },
  { label: "Class A yield", value: "class_A", inputType: "percentage" },
  {
    label: "Class B portion of Residual Value",
    value: "class_B",
    inputType: "percentage",
  },
  {
    label: "New Class C Pool Assignment - C17 Q2 2024 ($M)",
    value: "new_class_C_2024",
    inputType: "currency",
  },
  {
    label: "Vesting Period - C Class",
    value: "class_C_vesting_period",
    inputType: "number",
  },
  {
    label: "Share buyback in 2024",
    value: "share_buyback_2024",
    inputType: "currency",
  },
  {
    label: "Implied volume from C1 at",
    value: "share_buyback_rate_2024",
    inputType: "currency",
  },
  {
    label: "Vesting Period - D Class",
    value: "class_D_vesting_period",
    inputType: "number",
  },
  {
    label: "Target Value - D Class (% of 4-year forward value creation)",
    value: "class_D_target_value",
    inputType: "percentage",
  },
];

export const expandableRows = {
  "D Pool": ["2024 D Pool", "2025 D Pool", "2026 D Pool", "2027 D Pool"],
};

export const engineRows = [
  { label: "Base revenue (Core + Contingent)", value: "base_revenue" },
  { label: "Operating Expense", value: "opex" },
  {
    label: "Organic EBITDA (ASC 606)",
    value: "organic_ebitda_estimate_(asc_606)",
  },
  {
    label: "TTM EBITDA Estimates (Acquisition)",
    value: "ttm_ebitda_estimate_(acquisitions)",
  },
  {
    label: "TTM EBITDA Estimate (Core + Acquisitions) ASC 606",
    value: "ttm_ebitda_estimate_(core_acq)_asc_606",
  },
  { label: "Enterprise Value", value: "enterprise_value" },
  { label: "Total Enterprise Value", value: "total_enterprise_value" },
  {
    label: "Less Debt (presumes YE 2023 amount continues)",
    value: "less_debt",
  },
  {
    label: "Less Net Debt plus Earnout Liability",
    value: "less_net_debt_earnout",
  },
  { label: "Less HPS Prefered Equity ", value: "less_hps" },
  { label: "Imputed Equity Value", value: "imputed_value" },
  {
    label:
      "Imputed Equity Value using Multiple - net debt - HPS preferred equity",
    value: "imputed_value_new",
  },
  { label: "Investor Preferred Equity", value: "investor_preferred_equity" },
  {
    label: "Available to D, B & C Residual Units ",
    value: "available_residual_DBC",
  },
  { label: "D Pool", value: "D Pool" },
  { label: "2024 D Pool", value: "2024 D Pool" },
  { label: "2025 D Pool", value: "2025 D Pool" },
  { label: "2026 D Pool", value: "2026 D Pool" },
  { label: "2027 D Pool", value: "2027 D Pool" },
  {
    label: "Available to Participating B & C Residual Units",
    value: "available_residual_BC",
  },
  { label: "B Pool", value: "B Pool" },
  { label: "C Pool", value: "C Pool" },
  {
    label: "C Units Issued & Vested (in million units) (B)",
    value: "sub_total_all_c_vested_cummulative",
  },
  { label: "Per Unit Class C Value ($)", value: "per_unit_class_c_value($)" },
  { label: "Total Aggregate Threshold", value: "total_aggregated_threshold" },
  {
    label: "Total Gross Up Class C available Amount (A)",
    value: "total_gross_class_c_available_amt",
  },
  {
    label: "Distribution per unit (A/B = C)",
    value: "distribution_per_unit_c",
  },
  {
    label: "Available Amount for C / Outstanding Shares",
    value: "available_amt_c/outstanding_share",
  },
  {
    label: "Available Amount per Unit Class C",
    value: "total_available_amt_per_unit_class_c",
  },
];

export const years = [2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031];

export const Classes = [
  "C-1 ",
  "C-2 ",
  "C-3 ",
  "C-4 ",
  "C-5 ",
  "C-6 ",
  "C-7 ",
  "C-8 ",
  "",
  "",
  "C-9 ",
  "C-10 ",
  "C-11 ",
  "C-12 ",
  "C-13 ",
  "C-14 ",
  "",
  "C-15 ",
];

export const Quaters = [
  "Q220",
  "Q320",
  "Q420",
  "Q121",
  "Q221",
  "Q321",
  "Q421",
  "Q122",
  "Q1 Special awards - 2.25 yr",
  "Q1 Special awards - 3 yr",
  "Q222",
  "Q322",
  "Q422",
  "Q123",
  "Q223",
  "Q323",
  "Q3 special award",
  "Q423",
];
